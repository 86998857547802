import {
  WppCard,
  WppAccordion,
  WppTypography,
  WppActionButton,
  WppIconTrash,
  WppTooltip,
  WppIconCopy,
} from '@platform-ui-kit/components-library-react'
import { MouseEventHandler, useMemo } from 'react'
import { Controller, useFormContext } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

import { Flex } from 'components/common/flex/Flex'
import { FormPalette } from 'components/form/formPalette/FormPalette'
import { FormRichText } from 'components/form/formRichText/FormRichText'
import { FormSelect } from 'components/form/formSelect/FormSelect'
import styles from 'pages/processBuilder/editProcessModal/EditProcessModal.module.scss'
import { templateConfigs } from 'pages/processBuilder/templates'
import { getIsFieldHidden } from 'pages/processBuilder/utils'
import { FormPhase, Templates } from 'types/process/process'

export const GroupFields = ({
  index,
  isEditMode,
  onRemove,
  onCopy,
}: {
  index: number
  isEditMode: boolean
  onRemove: MouseEventHandler
  onCopy: MouseEventHandler
}) => {
  const { register, control, watch } = useFormContext()
  const formData = watch()
  const { t } = useTranslation()
  const isFieldHidden = getIsFieldHidden(formData.template as Templates)
  const colorsConfig = templateConfigs[formData.template as Templates].colors.group

  const groupPhasesOptions = useMemo(
    () =>
      formData?.phases?.map(({ localId }: FormPhase, index: number) => ({
        value: localId,
        label: `Phase ${index + 1}`,
      })) || [],
    [formData],
  )

  return (
    <WppCard className={styles.groupCard}>
      <WppAccordion withDivider={false} className={styles.phaseAccordion}>
        <WppTypography type="s-strong" slot="header">
          {t('process.edit_modal.group_number', { number: index + 1 })}
        </WppTypography>
        <WppActionButton className={styles.accordionActionBtn} variant="primary" onClick={onRemove} slot="actions">
          <WppIconTrash slot="icon-start" />
        </WppActionButton>

        <Flex direction="column" gap={10} className={styles.phaseFieldsWrap}>
          <Controller
            render={({ field }) => (
              <FormRichText
                {...field}
                name={`groups.${index}.title`}
                placeholder={t('process.edit_modal.title')}
                hidden={isFieldHidden('groups', 'title')}
              />
            )}
            name={`groups.${index}.title`}
            control={control}
          />

          <Controller
            render={({ field }) => (
              <FormRichText
                {...field}
                name={`groups.${index}.description`}
                placeholder={t('process.edit_modal.description')}
                hidden={isFieldHidden('groups', 'description')}
              />
            )}
            name={`groups.${index}.description`}
            control={control}
          />

          <Controller
            render={({ field }) => (
              <FormSelect
                withSearch
                placeholder="Children phases"
                {...field}
                options={groupPhasesOptions}
                defaultValue={[]}
                type="multiple"
                hidden={isFieldHidden('groups', 'phasesLocalIds')}
              />
            )}
            name={`groups.${index}.phasesLocalIds`}
            control={control}
          />

          <Flex gap={8}>
            <FormPalette
              useDefaultColors={!isEditMode}
              {...register(`groups.${index}.colors`)}
              title={t('common.colors')}
              colorsConfig={colorsConfig}
              hidden={isFieldHidden('groups', 'colors')}
              disableExpanding
              className={styles.colorsInput}
              actionsSlot={
                !!index && (
                  <WppTooltip config={{ placement: 'bottom' }} text={t('process.edit_modal.copy_group_colors_info')}>
                    <WppActionButton variant="primary" onClick={onCopy}>
                      <WppIconCopy slot="icon-start" />
                    </WppActionButton>
                  </WppTooltip>
                )
              }
            />
          </Flex>
        </Flex>
      </WppAccordion>
    </WppCard>
  )
}
