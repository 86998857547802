import { WppTooltip, WppTypography } from '@platform-ui-kit/components-library-react'
import clsx from 'clsx'
import { useState, useCallback } from 'react'

import { ReactComponent as InfoIcon } from 'assets/info-icon.svg'
import { Flex } from 'components/common/flex/Flex'
import styles from 'pages/phaseBuilder/legend/Legend.module.scss'
import {
  hideLegendSideModal,
  showLegendSideModal,
  useLegendSideModal,
} from 'pages/phaseBuilder/legendSideModal/LegendSideModal'
import { PhaseFormConfig, PhaseLegendVariant } from 'types/phase/phase'
import { getPreviewImageUrl } from 'utils/builder'

interface Props {
  config: PhaseFormConfig
}

export const Legend = ({ config }: Props) => {
  const [isTooltipShown, setIsTooltipShown] = useState(false)
  const { visible: isLegendSideModalVisible } = useLegendSideModal()

  const handleShowTooltip = () => {
    setIsTooltipShown(true)
  }

  const HandleHideTooltip = () => {
    setIsTooltipShown(false)
  }

  const handleToggleLegendSideModal = useCallback(() => {
    if (config?.legend?.variant === PhaseLegendVariant.TOOLTIP) return

    if (isLegendSideModalVisible) {
      hideLegendSideModal()
    } else {
      showLegendSideModal({ config })
    }
  }, [config, isLegendSideModalVisible])

  const handleTooltipOnShow = useCallback(() => {
    if (config?.legend?.variant === PhaseLegendVariant.SIDEBAR && config?.legend?.iconTooltip) {
      return
    }
    return false
  }, [config?.legend?.variant, config?.legend?.iconTooltip])

  if (!config?.legend?.isEnabled) return null

  return (
    <Flex gap={9} className={styles.tooltipContainer}>
      {config?.legend?.variant === PhaseLegendVariant.TOOLTIP && config?.legend?.image?.[0] && (
        <img
          src={getPreviewImageUrl(config.legend.image)}
          alt="Process Legend"
          className={clsx(styles.tooltipImg, isTooltipShown && styles.tooltipVisible)}
        />
      )}
      <WppTooltip
        config={{
          placement: 'left',
          arrow: false,
          allowHTML: true,
          onShow: handleTooltipOnShow,
        }}
      >
        <div
          slot="tooltip-content"
          className={clsx(styles.iconTooltip, {
            [styles.iconTooltipCardDisabledOffset]: !config?.card?.isEnabled,
          })}
          style={{ backgroundColor: config?.legend?.colors?.[7] }}
        >
          <WppTypography
            slot="tooltip-content"
            type="m-strong"
            className={styles.iconTooltipText}
            style={{ color: config?.legend?.colors?.[8] }}
          >
            {config?.legend?.iconTooltip}
          </WppTypography>
        </div>
        <Flex
          justify="center"
          align="center"
          className={clsx(styles.legendTrigger, {
            [styles.legendSideModalTrigger]: config?.legend?.variant === PhaseLegendVariant.SIDEBAR,
          })}
          style={{
            backgroundColor: config?.legend?.colors?.[0],
            margin: config?.card?.isEnabled ? '4px 19px 0 0' : '-33px 0 0 0',
          }}
          onMouseEnter={handleShowTooltip}
          onMouseLeave={HandleHideTooltip}
          onClick={handleToggleLegendSideModal}
        >
          {config?.legend?.iconImage?.[0] ? (
            <img src={getPreviewImageUrl(config.legend.iconImage)} className={styles.icon} alt="Legend Icon" />
          ) : (
            <InfoIcon stroke={config?.legend?.colors?.[1] || '#ffffff'} />
          )}
        </Flex>
      </WppTooltip>
    </Flex>
  )
}
