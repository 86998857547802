import { WppActionButton, WppIconChevron, WppButton, WppRichtextView } from '@platform-ui-kit/components-library-react'
import { useOs } from '@wpp-open/react'
import clsx from 'clsx'
import { ReactNode, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { Link, useSearchParams } from 'react-router-dom'

import { Flex } from 'components/common/flex/Flex'
import { Tenants } from 'constants/tenants'
import { Legend } from 'pages/phaseBuilder/legend/Legend'
import styles from 'pages/phaseBuilder/templates/simpleCard/processCard/ProcessCard.module.scss'
import { PhaseFormConfig } from 'types/phase/phase'
import { getPreviewImageUrl, normalizeDimensions } from 'utils/builder'
import { routesManager } from 'utils/routesManager'

interface Props {
  children: ReactNode
  config: PhaseFormConfig
}

export const ProcessCard = ({ children, config }: Props) => {
  const {
    osContext: { tenant },
  } = useOs()
  const { t } = useTranslation()
  const [searchParams] = useSearchParams()
  const nameQuery = searchParams.get('name') !== null ? `?name=${searchParams.get('name')}` : ''
  const shouldNavigateBackToCustom = searchParams.get('back-to-custom') !== null

  const isHeaderTransparent = useMemo(
    () => !config.header?.colors?.[1] && !config.header?.background[0],
    [config.header?.background, config.header?.colors],
  )

  const previousLink = config?.controls?.previousUrl
    ? `${config?.controls?.previousUrl}${shouldNavigateBackToCustom ? '?back-to-custom' : ''}`
    : ''
  const nextLink = config?.controls?.nextUrl
    ? `${config?.controls?.nextUrl}${shouldNavigateBackToCustom ? '?back-to-custom' : ''}`
    : ''

  const contentHeight = useMemo(
    () =>
      `calc(100vh - var(--wpp-os-header-height) - ${config.header.height}px - 
    ${!config.header.isFullWidth && !isHeaderTransparent ? '24px' : '0px'})`,
    [config.header.height, config.header.isFullWidth, isHeaderTransparent],
  )

  const logo = useMemo(() => getPreviewImageUrl(config?.header?.logoImage), [config?.header?.logoImage])
  const { width: logoWidth, height: logoHeight } = useMemo(
    () => normalizeDimensions(config.header?.logoDimensions),
    [config.header.logoDimensions, config.header.logoDimensions.width, config.header.logoDimensions.height], //eslint-disable-line
  )

  const customTranslation =
    tenant.name === Tenants.YumChina
      ? { backToProcess: '返回6A流程', previousLink: '上一阶段', nextLink: '下一阶段' }
      : {
          backToProcess: t('phase.page.back_to_process'),
          previousLink: t('phase.page.previous_phase'),
          nextLink: t('phase.page.next_phase'),
        }

  return (
    <div
      className={clsx(styles.root, {
        [styles.containedHeaderOffset]: !config.header.isFullWidth && !isHeaderTransparent,
      })}
    >
      <div
        className={clsx([
          styles.header,
          {
            [styles.headerTransparent]: isHeaderTransparent,
            [styles.headerContained]: !config.header.isFullWidth,
          },
        ])}
        style={{
          backgroundImage: `url(${getPreviewImageUrl(config?.header?.background)})`,
          backgroundPosition: config?.header?.positionBackground,
          height: config.header.height,
        }}
      >
        <div className={styles.bgColor} style={{ background: config?.header?.colors?.[1] }} />
        <Flex justify="between" align="center" gap={24} className={styles.headerContent}>
          <Flex direction="column" className={styles.headerTextContent} justify="center" gap={10}>
            <Link
              to={shouldNavigateBackToCustom ? { pathname: routesManager.customProcess.url, search: nameQuery } : '..'}
            >
              <WppActionButton variant="inverted" className={styles.backButton}>
                <WppIconChevron direction="left" color={config?.header?.colors?.[0]} />
                <span style={{ color: config?.header?.colors?.[0] }}>{customTranslation.backToProcess}</span>
              </WppActionButton>
            </Link>
            {config?.header?.title && (
              <WppRichtextView
                className={styles.headerTitle}
                value={config.header.title}
                style={{ color: config.header?.colors?.[0] }}
              />
            )}
            {config?.header?.description && (
              <WppRichtextView
                className={styles.headerDescription}
                value={config.header.description}
                style={{ color: config.header?.colors?.[0] }}
              />
            )}
          </Flex>

          {logo && (
            <Flex justify="end" align="center" className={styles.logoWrap}>
              <img src={logo} alt="" className={styles.logo} style={{ width: logoWidth, height: logoHeight }} />
            </Flex>
          )}
        </Flex>
      </div>

      <div
        className={styles.mainContent}
        style={{
          height: config.card.isScrollable ? contentHeight : 'auto',
        }}
      >
        <div
          className={clsx(styles.processCard, {
            [styles.processCardDisabled]: !config?.card?.isEnabled,
          })}
          style={{
            backgroundImage: `url(${getPreviewImageUrl(config?.card?.background)})`,
          }}
        >
          <div className={styles.cardBgColor} style={{ background: config?.card?.colors?.[0] }} />

          <Flex direction="column" justify="between" gap={24} className={styles.cardContent}>
            {config?.legend?.isEnabled && (
              <div className={clsx(!config?.card?.isEnabled && styles.legendTooltipWrapper)}>
                <Legend config={config} />
              </div>
            )}

            <div className={styles.flexGrow}>
              <Flex align={config.card.contentImage[0] ? 'start' : 'center'} className={styles.descriptionCardsWrapper}>
                <Flex justify="between" gap={24} className={styles.descriptionCardsWrapperInner}>
                  {children}
                </Flex>
              </Flex>
            </div>
          </Flex>

          <Flex align="center" justify="between" className={styles.processControls}>
            <Flex gap={16}>
              <Link to={previousLink}>
                <WppActionButton variant="secondary" disabled={!previousLink} className={styles.navButton}>
                  <WppIconChevron direction="left" color={config?.controls?.colors?.[0]} />
                  <span style={{ color: config?.controls?.colors?.[0] }}>{customTranslation.previousLink}</span>
                </WppActionButton>
              </Link>
              <Link to={nextLink}>
                <WppActionButton variant="secondary" disabled={!nextLink} className={styles.navButton}>
                  <span style={{ color: config?.controls?.colors?.[0] }}>{customTranslation.nextLink} </span>
                  <WppIconChevron color={config?.controls?.colors?.[0]} />
                </WppActionButton>
              </Link>
            </Flex>
            {config?.controls?.button?.isVisible && (
              <Link to={config?.controls?.button?.url || ''}>
                <WppButton
                  className={styles.projectBtn}
                  style={{ background: config?.controls?.colors?.[1], color: config?.controls?.colors?.[2] }}
                >
                  {config?.controls?.button?.title}
                </WppButton>
              </Link>
            )}
          </Flex>
        </div>
      </div>
    </div>
  )
}
